@import "../../style.scss";

.message-container{
  @include themify($themes) {
    .chat-window {      
      background-color: themed('bgSoft');
      flex-grow: 1;
      display: flex;
      flex-direction: column;    

      .chat-messages {
        flex-grow: 1;
        overflow-y: auto;
        // padding: 10px;

        .nameContainer {
          display: flex;
          align-items: center;
          // justify-content: center;
          border-bottom: 1px solid #ccc;
          padding: 20px;
          position: sticky;
          top: 0px;
          background-color: themed("bg");

          div {
            margin-left: 10px;
          }
        }

        .message {
          padding: 10px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;

          // .message-content {
          //   display: flex;
          //   align-items: center;
          //   .message-text {
          //       max-width: 80%;
          //   }
          //   .sender-badge {
          //       background-color: #f0f0f0;
          //       color: #555;
          //       padding: 2px 8px;
          //       border-radius: 12px;
          //       margin-left: 8px;
          //       font-size: 12px;
          //       font-weight: bold;
          //   }
          // }
          &.friend{
            .msg-container{
              display: flex;
              align-self: flex-start;
              align-items: end;
              background-color: #93a3fd;
              border-radius: 10px;
            }
            .fwd-ico{
              // background-color: brown;
              border-radius: 50%;
              display: flex;
              flex-direction: column-reverse;
              height: 15px;
              width: 15px;
              padding: 4px;
              color: #000;
              cursor: pointer;
            }
          }

          &.me{ 
            .msg-container{
              display: flex;
              align-self: flex-end;
              align-items: end;
              background-color: lightsteelblue;
              border-radius: 10px;
            }
            .fwd-ico{
              // background-color: brown;
              border-radius: 50%;
              display: flex;
              flex-direction: column-reverse;
              height: 15px;
              width: 15px;
              padding: 4px;
              color: #000;
              cursor: pointer;
            }
            &.friend{
              .fwd-ico{
                background-color: brown;
                border-radius: 50%;
                display: flex;
                flex-direction: column-reverse;
                height: 15px;
                width: 15px;
                padding: 4px;
                color: yellow;
              }
            }
          }

          span {
            &.me {
              // background-color: #cd6fff;
              align-self: flex-end;
              padding: 12px;
              border-radius: 20px;
              display: flex;
              flex-direction: column;
            }

            &.friend {
              background-color: #93a3fd;
              align-self: flex-start;
              padding: 12px;
              border-radius: 20px;
            }
            &.my-msg{
              background-color: honeydew;
              color: forestgreen;
              font-weight: bold;
              align-self: flex-end;              
              border-radius: 5px;
              display: flex;
              flex-direction: column;
            }
            &.friends-msg{              
              color: rgb(236, 81, 8);
              font-weight: bold;              
            }
          }
        }
      }

      .input-container {
        display: flex;
        padding: 10px;
        border-top: 1px solid #ccc;
        background-color: themed("bg");
        position: sticky;

        input {
          flex-grow: 1;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        button {
          margin-left: 10px;
          padding: 5px 10px;
          background-color: #4CAF50;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .select-friend {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 18px;
      }
    }
  }
}