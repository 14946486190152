.reels-home{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .input-container{
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px;
      resize: none;
      height: 80px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      flex-direction: column; 
      cursor: pointer;
      margin-top: 15px;
    }

// .video-page {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     background: black;
//     height: 835px;
//     width: 450px;  
    
//   }
//   .user-info {
//     position: absolute;
//     top: 10px;
//     left: 10px;
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     z-index: 2;
//     background-color: rgba(0, 0, 0, 0.5);
//     padding: 5px;
//     border-radius: 5px;
//   }

//   .user-pic {
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
//     object-fit: contain;
//   }

//   .user-name {
//     font-weight: bold;
//     font-size: 1rem;
//     color: white;
//   }
 

.video-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
  height: 815px;
  width: 450px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px; 
  border-radius: 10px;
}

.user-info {
  position: absolute;
  bottom: 10px; 
  left: 10px; 
  display: flex;
  flex-direction: column;
  // align-items: center;
  // gap: 10px;
  justify-content: space-between;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  padding: 5px;
  border-radius: 5px;
  width: 420px;
}

.user-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
}

.user-name {
  font-weight: bold;
  font-size: 1rem;
  color: white;
}

  .video-rec-container{
    display: flex;
  }
  
  .video {
    width: 100%;
    max-width: 480px;
    max-height: 850px;
  }

  .backBtn-container{
    display: flex;
    flex-direction: column;
    align-self: anchor-center;
    
  }

  .reaction-container{
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    gap: 215px;
    margin-bottom: 10px;
  }
  
  .icons-container {
    // align-self: flex-end;
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
  
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .like-btn, .comment-btn {
    background: none;
    border: none;
    font-size: 24px;
    color: black;
    cursor: pointer;
  }    
  
  .comments {
    margin-bottom: 15px;
    overflow-x: overlay;
    max-height: 821px;
  }    
}