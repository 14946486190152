.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: white;
  margin: 20px 0px 20px 0px;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease;
  width: 80%;
}

.carousel-card {
  margin: 10px 10px;
  padding: 20px;
  background-color: #f3f3f3;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  opacity: 0.5;
  transform: scale(0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;

  &.left {
    width: 20%;
  }

  &.right {
    width: 20%;
  }

  &.center {
    width: 60%;
    opacity: 1;
    transform: scale(1);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  &:hover {
    opacity: 1;
    transform: scale(1.05);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  }

  .card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .card-video {
      margin-bottom: 10px;
      position: relative;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      min-height: 60vh;

      .reel-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    /* Positioning the user info (pic + name) at top-left corner */
    .user-info {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 5px;
      border-radius: 5px;
    }

    .user-pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: contain;
    }

    .user-name {
      font-weight: bold;
      font-size: 1rem;
      color: white;
    }

    .reaction-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 40%;
      align-items: center;
      gap: 20px;
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0);
      padding: 5px;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
    }

    &:hover .reaction-container {
      opacity: 1;
      visibility: visible !important;
    }

    .reaction-container .item-con {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      font-size: 14px;
      color: white;
    }
  }
}

