.link-preview {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    // max-width: 600px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    a {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: inherit;
      width: 100%;
      gap: 5px;
    }
   
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 15px;
    }
   

  }