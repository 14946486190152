@import "../../style.scss";

.noGroups {
  @include themify($themes) {
    background-color: themed("bg");
    color: themed("textColor");
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 110px);
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
    button {
      margin-top: 20px;
      padding: 10px 20px;
      background-color: themed("primary-color");
      color: themed("textColor");
      border: none;
      border-radius: 5px;
      cursor: pointer;
      a {
        color: themed("text-color");
        text-decoration: none;
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
}

.selectGroups {
  @include themify($themes) {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: themed('bg');
    color: themed('textColor');
    height: calc(100vh - 110px);
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      p {
        font-weight: 600;
        font-size: 2rem;
        display: flex;
        align-items: center;
        div {
          width: 1rem;
          height: 1rem;
          border-right: 2px solid black;
          border-bottom: 2px solid black;
          border-radius: 50%;
          animation: spin 0.8s linear infinite;
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      button {
        margin-right: 20px;
        padding: 10px 20px;
        background-color: themed("primary-color");
        color: themed("textColor");
        border: none;
        border-radius: 5px;
        cursor: pointer;
        a {
          color: themed("text-color");
          text-decoration: none;
          font-size: 0.8rem;
          font-weight: 700;
        }
      }
    }

    .usergroups {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(2, 1fr); 
      gap: 10px;
      cursor: pointer;
      @include mobile {
        grid-template-columns: 1fr; 
      }

      .group-container {
        display: flex;
        justify-content: space-between;
        background-color: themed("bg");
        text-decoration: none;
        border-radius: 10px;
        width: 100%;
        height: auto;
        gap: 20px;
        cursor: pointer;
        box-shadow: 0px 5px 15px themed("border");

        .user {
          display: flex;
          gap: 10px;
          .left {
            display: flex;
            align-items: center;
            img {          
              height: auto; 
              max-width: 150px; 
              border-radius: 10px;
              object-fit: cover;
            }
          }
          .right {
            width: 100%; 
            display: flex;
            flex-direction: column;
            justify-content: center;

            h4, h5, h6 {
              word-wrap: break-word; 
            }
            h4 {
              color: themed("textColor");
            }
            h5 {
              color: themed("textColorSoft");
            }
            h6 {
              color: themed("textColor");
            }
          }
        }

        .deleteGrp {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 10px;
          button {
            border: none;
            background-color: themed("bg");
            cursor: pointer;
          }
        }
      }
    }
   
    @include mobile {
      .group-container {
        flex-direction: column; 
        gap: 10px; 
        height: auto;
      }

      .user {
        .left {
          img {
            max-width: 100%; 
            height: auto;
          }
        }

        .right {
          h4, h5, h6 {
            font-size: 1rem; 
          }
        }
      }
    }
  }
}
