@import "../../style.scss";

.login {

  @include themify($themes) {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;


    @include tablet {}
  }



  .card {
    display: flex;
    // background-color: #fce6e3;
    background-color: white;
    flex: 1;
    height: 100vh;
    min-height: 600px;
    max-height: 1100px;
    max-width: 2000px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    // .left {
    //   flex: 1;
    //   background: linear-gradient(rgba(41, 33, 40, 0.345), rgba(75, 21, 39, 0.295)),
    //     url("../../assets/login_image.jpg") center;
    //   background-size: cover;
    //   padding: 50px;
    //   display: flex;
    //   flex-direction: column;
    //   gap: 30px;
    //   color: white;

    //   h1 {
    //     font-size: 100px;
    //     line-height: 100px;
    //     text-shadow: 2px 3px 6px #672a2a;
    //   }

    //   p {
    //     text-shadow: 2px 3px 6px #672a2a;
    //   }
    // }

    .left{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // background: linear-gradient(rgba(41, 33, 40, 0.345), rgba(75, 21, 39, 0.295));
      // background:#7e78ee;
      // background:#405d72;
      background:#012e6b;

     

      h1{
        font-size: 2rem;
        font-weight: 700;
        color: white;
      }
      
      .outer{
        width: 75%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #10386d;
        border-radius: 50%;
        animation: bounce 3s infinite ease-in-out;
      }
      .middle{
        width: 75%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #193e6e;
        border-radius: 50%;
        animation: bounce 5s infinite ease-in-out;
      }
      .inner{
        width: 75%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1e416f;
        border-radius: 50%;
        animation: bounce 3s infinite ease-in-out;
      }

      .image{
        flex: 1;
        // object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        // padding: 20px;
        background:#f3e9d4 url("../../assets/logo_symbol.png") no-repeat center ;
        background-size: cover;
      }

     
    }

    .right {
      flex: 1;
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      justify-content: center;
      align-items: center;
      text-align: center;

      .main{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;
      }
      @include mobile{
        .main{
          width: 100%;
        }
      }

      .head{
        // h1 {
          //   color: #555;
          //   text-align: center;
          //   font-size: xx-large;
          //   margin-bottom: 2%;
          //   font-size: 3rem;
          // }
          p{
            color: #555;
            font-weight:700;
            font-size: 1.5rem;
            // margin-top: -20px;
        }
      
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 30px;

        input {
          border: none;
          border-bottom: 1px solid lightgray;
          padding: 10px 10px;
          font-size: medium;
          width: 100%;
        }
        
        input:focus{
          outline: none;
        }

        .options{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 10px;
          align-items: center;
        }
       
       
        button {
          width: 50%;
          padding: 10px;
          border: none;
          // background-color: #d8ae7e;
          background-color: #012e6b;
          color: white;
          font-weight: bold;
          cursor: pointer;
          font-size: large;
          border-radius: 10px;

          .loader{
            display: flex;
            justify-content: center;
            align-items: center;
            p{
              color: white;
              font-weight: 700;
              font-size: medium;
              margin-right: 5px;
            }
          }


          .spinner{
            width: 1rem;
            height: 1rem;
            border: 2px solid white;
            border-radius: 50%;
            border-top: none;
            border-right: none;
            animation: spin 0.4s linear infinite ;
          }
        }

        button:hover{
          background-color: #063a83;
          transition:  all 0.5s ease;
        }

        .error {
          color: rgb(255, 51, 51);
          font-size: 14px;
          text-align: left;
        }

        p{
          color: skyblue;
          font-size: 0.9rem;
          text-decoration: none;
          font-weight: 600;
        }

        .link-forgot{
          color: gray;
          text-decoration: none;
        }
      }

      
      p {
        // display: none;
        font-size: 0.9rem;
        color: black;
        margin-top: 5px;
        font-weight: 600;

        .link-signup{
          color: gray;
          text-decoration: none;
        }


        // @include mobile {
        //   display: block;
        // }
      }
    }
  }

  @include mobile {
    .card {
      .left {
        display: none;
        flex: 0;
      }
    }
  }
}

@keyframes bounce {
    0%, 100% {
      padding: 50px;
    }
    50% {
      padding:0px;
    }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}