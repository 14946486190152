@import "../../../adminStyle.scss";

.adminNavbar {
  @include themify($themes) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    height: 50px;
    border-bottom: 1px solid themed("border");
    position: sticky;
    top: 0;
    background-color: themed("bg");
    color: themed("textColor");
    z-index: 999;

    .left {
      display: flex;
      align-items: center;
      gap: 30px;

      a {
        img{
          height: 70px;
        }
      }
      span {
        font-weight: bold;
        font-size: 20px;
        color: themed("logo");
      }

      .search {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid themed("border");
        border-radius: 5px;
        padding: 5px;
        position: relative;

        input {
          border: none;
          width: 500px;
          background-color: transparent;
          color: themed("textColor");
          outline: none;

          @include mobile {
            display: none;
          }

          @include tablet {
            width: 200px;
          }
        }

        .searchResultsContainer{
          position: absolute;
          top: 100%;
          left: -1px;
          background-color: themed("bgSoft");
          border: 1px solid themed("border");
          border-top: none;
          max-height: 300px;
          overflow-y: auto;
          z-index: 999;
          height: 200px;
          width: 100%;

          li {
            padding: 10px;
            cursor: pointer;

            &:hover {
              background-color: themed("bgHover");
            }
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 20px;

      @include mobile {
        // display: none;
      }

      .user {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;

        @include tablet {
          display: none;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-style: inherit;
          color:themed("textColor");

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            margin-left: 10px;
            @include profile-name;
          }
        }
      }

      button {
      @include button-primary;
      background-color:#e4002b;
      color: white;
      padding: 6px;
      }
    }
  }
}