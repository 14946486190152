@import "../../style.scss";

.update {
  @include themify($themes) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .wrapper {
      margin: auto;
      border-radius: 10px;
      width: 40%;
      height: 70%;
      background-color: themed("bg");
      padding: 50px;
      z-index: 999;
      display: flex;
      flex-direction: column;
      gap: 20px;
      -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      position: relative;
      
      @include mobile {
        width: 100%;
        height: 100%;
      }

      h1 {
        color: black;
      
        @include mobile {
         font-size: 20px;
        }
      
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .files {
          display: flex;
          flex-wrap: wrap;
          gap: 50px;
        }

        label {
          // display: flex;
          // flex-direction: column;
          // gap: 10px;
          color: rgb(44, 44, 44);
          // font-size: 12px;

          .imgContainer {
            position: relative;
            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
            }

            .icon {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              font-size: 30px;
              color: lightgray;
              cursor: pointer;
            }
          }
        }

        .input-wrapper{
          display: flex;
          // justify-content: center;
          align-items: center;
          width: 100%;
          gap: 20px;
          
          div {
            width: 50%;

          }

          @include mobile {
            display: block;

            div{
              width: 100%;
            }
          }


        }

        div{

        input {
          padding: 5px;
          border: none;
          border-bottom: 1px solid themed("border");
          color: gray;
          background-color: transparent;
          width: 100%;
        }

        select {
          padding: 5px;
          border: none;
          border-bottom: 1px solid themed("border");
          color: gray;
          background-color: transparent;
          width: 100%;
        }
        #password-ag{
          font-size: small;
            input{
              width: 20%;
  
          } 
        }
      }

        button {
          border: none;
          padding: 10px;
          cursor: pointer;
          color: themed("text-color");
          background-color: themed("primary-color");
          border-radius: 5px;
        }
      }

      .close {
        position: absolute;
        top: 10px;
        right: 20px;
        border: none;
        background-color: #f0544f;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        color: white;
      }
    }
  }
}
