@import "../../style.scss";

.post {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    // max-width: 53vw;
    width: 80%;
    margin: auto;

    @include mobile {
      width: 100%;
    }

    .container {
      padding: 20px;
      width: "100%";
      overflow: hidden;
      word-wrap: break-word;
      margin-top: 5px;

      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .userInfo {
          display: flex;
          gap: 20px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            display: flex;
            flex-direction: column;

            .name {
              font-weight: 500;
            }

            .date {
              font-size: 12px;
            }
          }
        }

        .moreOptions {
          width: 100px;
          border-radius: 5px;
          position: absolute;
          top: 30px;
          right: 0;
          margin: auto;
          padding: 10px;
          background-color: themed("bg");
          box-shadow: 2px 2px 20px themed("border");
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .more-button {
          margin: 5px;
          padding: 5px;
          cursor: pointer;
          color: themed("textColor");
          font-weight: 600;
          font-size: 0.8rem;
          border-radius: 5px;
          border: none;
          display: flex;
          align-items: center;
          background-color: themed('bg');
          gap: 3px;
        }
      }

      .content {
        margin: 20px 0px;

        .postName {
          // max-width: calc(100vw - 55vw);
          width: 95%;

          a {
            &:hover {
              color: themed("hoverColor");
            }

            // @include mobile {
            //   max-width: 100vw !important;
            // }
          }
        }

        .video-post {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        video {
          max-width: 100%;
          height: auto;
          max-height: 70vh;
          object-fit: contain;
        }

        img {
          width: 100%;
          max-height: 500px;
          object-fit: contain;
          margin-top: 20px;
        }
      }

      .info {
        display: flex;
        align-items: center;
        gap: 20px;

        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }
}