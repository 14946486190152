@import "../../style.scss";

.reels-container {
  @include themify($themes) {
    display: flex;
    height: calc(100vh - 110px);
 
  
  .left-section {
    width: 20%;
    padding: 20px;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    .top-site{
      // padding: 20px;      
      display: flex;
      flex-direction: column;
      align-items: center;   
      gap: 5px;

      .input-container{
        display: flex;
        align-items: center;
        gap: 5px;
        // width: 100%;
        padding: 10px;
        resize: none;
        height: 80px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        flex-direction: column; 
        cursor: pointer;
      }

      .unselect-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: end;
        height: 20px;
        width: 75px;
        background: rgb(187 13 13);
      }

      
    }
    
    .post-btn{
      padding: 10px 20px;
      border: none;
      background-color: #7a848f;
      color: rgb(7, 0, 0);
      cursor: not-allowed;
      border-radius: 5px;
      font-size: 16px; 
     
      &:hover {
        background-color: #7a848f;
      }
      
    }
    .loader{
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        color: #fff;
        font-weight: 700;
        font-size: medium;
        margin-right: 5px;
      }
    }  

    .spinner{
      width: 1rem;
      height: 1rem;
      border: 2px solid #fff;
      border-radius: 50%;
      border-top: none;
      border-right: none;
      animation: spin 0.4s linear infinite ;
    }
    h2 {
      font-size: 18px;
      text-align: center;
    }
  
    input[type="file"] {
      margin-bottom: 10px;
    }
  
    .video-preview {
      margin-top: 15px;
      max-height: 400px;
      video {
        width: 100%;
      }
    }
  
    textarea {
      width: 100%;
      padding: 10px;
      resize: none;
      height: 80px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  
    button {
      padding: 10px 20px;
      border: none;
      background-color: #007bff;
      color: white;
      cursor: pointer;
      border-radius: 5px;
      font-size: 16px;
  
      &:hover {
        background-color: #0056b3;
      }
    }
    
  }  
  
  .right-section {
    width: 80%;
    max-width: 1250px;     
    max-height: 800px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // overflow: hidden; 
    margin: 0 auto; 
    gap: 10px;
    background-color: whitesmoke;

    .preview-container{
      max-width: 1220px;
      max-height: 740px;
    }
  }  
   
  video {
    width: 100%; 
    height: auto; 
    max-height: 100%; 
    object-fit: contain; 
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  p {
    font-size: 18px;
    color: #888;
  }
}
}
  