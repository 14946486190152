@import "../../adminStyle.scss";

.adminUserManager {
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: calc(100vh -    100px);
    background-color: themed("bgSoft");
    color: themed("textColor");

    @include mobile {
      padding: 10px;
      min-height: calc(100vh -    100px);
    }

    @include tablet{
      padding: 20px;
    }

    .top-bar-container{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 20px;

      .menu-left{
        display: flex;
        align-items: center;
        .search {
          display: flex;
          align-items: center;
          gap: 10px;
          border: 1px solid themed("border");
          border-radius: 5px;
          padding: 5px;
          position: relative;
  
          input {
            border: none;
            width: 300px;
            background-color: transparent;
            color: themed("textColor");
            outline: none;
  
            @include mobile {
              display: none;
            }
  
            @include tablet {
              width: 200px;
            }
          }
      }
      .submit-btn{
        font-size: 1rem;
        background-color: #f44336;
        color: white;
        padding: 6px;
        border-radius: 10px;
        margin-left: 5px;
        :hover{
          background-color: #da190b;
        }
      }
    }

      .menu-right{
        button{
          background-color: forestgreen;
          color: themed("bg");
          border: none;
          border-radius: 5px;
          padding: 10px 20px;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    
    .user-list {

      .heading-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;

        .image-heading{
          width: 10%;
          h3{
            text-align: center;
          }
        }

        .info-heading{
          width: 70%;
          display: flex;
          justify-content: space-between;

          .info-heading-common {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26%;
            h3{
              text-align: center;
            }
          }
        }

        .button-heading{
          width: 20%;
          h3{
            text-align: center;
          }
        }

      }



      display: grid;
      margin: 20px;
      .user-card {
        display: flex;
        align-items: center;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-around;

        .image-container {
          width: 10%;
          img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 50%;
          }
  
        }
     
        .user-info {
          width: 70%;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .info-container-common {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26%;
          }
          .post-count{
            text-decoration: underline;
            width: 10%;
             span{
               width: 30%;
             }
             text-align: center;
            :hover{
              background-color: #ff6e67;
              color: rgb(255, 255, 255);
              font-weight: 500;
            }
           }
        }


        .button-container {
          width: 20%;
          display: flex;
          justify-content: center;
          .delete-btn {
            margin-top: 10px;
            padding: 5px 10px;
            background-color: #f44336;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            justify-self: end;
            &:hover {
              background-color: #da190b;
            }
          }

          .seePost-btn{
            margin-top: 10px;
            padding: 5px 10px;
            background-color: transparent;
            color: #f44336;
            border: 1px solid #aeaeaf;
            border-radius: 5px;
            cursor: pointer;
            justify-self: end;
            margin-right: 5px;
            &:hover {
              background-color: #ff6e67;
              color: rgb(255, 255, 255);
              font-weight: 500;
            }
          }
        }

   
      }
    }
  }
}