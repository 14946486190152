@import "../../style.scss";

.register {

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    display: flex;
    flex:1;
    flex-direction: row-reverse;
    background-color: white;
    height: 100%;
    min-height: 600px;
    max-height: 1100px;
    max-width: 2000px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    // .left {
    //   flex: 1;
    //   background: linear-gradient(rgba(41, 33, 40, 0.345), rgba(75, 21, 39, 0.295)),
    //     url("../../assets/register_image.jpg")
    //       center;
    //   background-size: cover;
    //   padding: 50px;
    //   display: flex;
    //   flex-direction: column;
    //   gap: 30px;
    //   color: white;
    //   background-size:cover ;
      

    //   h1 {
    //     font-size: 100px;
    //     line-height: 100px;
    //     text-shadow: 2px 3px 6px #672a2a;
    //   }
      
    //   p {
    //     text-shadow: 2px 3px 6px #672a2a;
    //   }

    //   span {
    //     font-size: 14px;
    //     text-shadow: 2px 3px 6px #672a2a;
    //   }
      
    //   button {
    //     width: 50%;
    //     padding: 10px;
    //     border: none;
    //     background-color: white;
    //     color: rebeccapurple;
    //     font-weight: bold;
    //     cursor: pointer;
    //   }
    // }

    .left{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // background: linear-gradient(rgba(41, 33, 40, 0.345), rgba(75, 21, 39, 0.295));
      background:#012e6b;

      h1{
        font-size: 2rem;
        font-weight: 700;
        color: white;
      }
      
      .outer{
        width: 75%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #10386d;
        border-radius: 50%;
        animation: bounce 3s infinite ease-in-out;
      }
      .middle{
        width: 75%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #193e6e;
        border-radius: 50%;
        animation: bounce 5s infinite ease-in-out;
      }
      .inner{
        width: 75%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1e416f;
        border-radius: 50%;
        animation: bounce 3s infinite ease-in-out;
      }

      .image{
        flex: 1;
        // object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        // padding: 20px;
        background:#f3e9d4 url("../../assets/11.jpg") center no-repeat;
        background-size: cover;
      }

     
    }

    .right {
      flex: 1;
      padding: 50px;
      // padding-top: 10px;
      // padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      justify-content: center;

      .head{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
          color: #555;
          font-size: xx-large;
          margin-bottom: 2%;
          font-size: 3rem;
        }
        p{
          color: #555;
          font-weight:700;
          font-size: 1.5rem;
          text-align: center;
        }
      } 
      h1 {
      color: #555;
      font-size: xx-large;
      }
      
      form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        input {
        border: none;
        border-bottom: 1px solid lightgray;
        padding: 10px 10px;
        font-size: medium;
        }

        input:focus{
          outline: none;
        }

        .input-wrapper{
          display: flex;
          gap: 30px;
          div{
            width: 50%;
            display: flex;
            flex-direction: column;
          }
          // select{
          //   width: 50%;
          // }
        }

        .terms-wrapper{
          a{
            color: #063a83 !important;
            font-weight: 600;
          }

        }

        .terms-container {
          padding: 20px;
          background-color: #f9f9f9;
          border-radius: 10px;
          max-width: 800px;
          margin: 0 auto;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        
          h2 {
            text-align: center;
            font-size: 2rem;
            color: #333;
            margin-bottom: 20px;
          }
        
          .terms-section {
            margin-bottom: 20px;
        
            h3 {
              font-size: 1.5rem;
              color: #0056b3;
              margin-bottom: 10px;
              position: relative;
              padding-left: 10px;
        
              &::before {
                content: "";
                width: 4px;
                height: 100%;
                background-color: #0056b3;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
        
            p {
              font-size: 1rem;
              line-height: 1.6;
              color: #666;
              padding-left: 10px;
        
              a {
                color: #0056b3;
                text-decoration: none;
                font-weight: bold;
        
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        
          .terms-acceptance {
            display: flex;
            align-items: center;
            margin-top: 30px;
        
            input[type="checkbox"] {
              margin-right: 10px;
              accent-color: #0056b3;  // Changes the checkbox color in modern browsers
            }
        
            label {
              font-size: 1rem;
              color: #333;
            }
          }
        }
        

        @include mobile{
          .input-wrapper{
            flex-direction: column;
            gap: 10px;

            input{
              font-size: small;
              padding: 5px 10px;
            }

            input[type="date"] {
              width:95%;
              position: relative;

              &::before {
                content: attr(placeholder); // Use the attribute as content
                position: absolute;
                left: 10px;
                top: 10px;
                // color: #999;
                pointer-events: none; // Prevent interactions with placeholder
              }

              &:valid::before {
                content: 'ddd';
              }

              &:invalid::before {
                content: 'Invalid date';
              }
          
              &:focus::before {
                content: '';
              }
             
            }

            select{
              background-color: inherit;
              font-size: small;
              padding: 5px 10px;
            }

            div{
              width: 100%;
            }
          }
        }

        select {
          border: none;
          border-bottom: 1px solid lightgray;
          padding: 10px 10px;
        }
        
        button {
          width: 50%;
          padding: 10px;
          border: none;
          background-color: #012e6b;
          color: white;
          font-weight: bold;
          cursor: pointer;
          font-size: large;
          border-radius: 10px;
          margin: auto;

          .loader{
            display: flex;
            justify-content: center;
            align-items: center;
            p{
              color: white;
              font-weight: 700;
              font-size: medium;
              margin-right: 5px;
            }
          }


          .spinner{
            width: 1rem;
            height: 1rem;
            border: 2px solid white;
            border-radius: 50%;
            border-top: none;
            border-right: none;
            animation: spin 0.4s linear infinite ;
          }
        }

        button:hover{
          background-color: #063a83;
          transition: all 0.5s ease;
        }

        .error {
          color: rgb(255, 51, 51);
          font-size: 14px;
        }
      }
      p {
        // display: none;
        font-size: 0.9rem;
        color: black;
        margin-top: 5px;
        font-weight: 600;
        text-align: center;

        .link-signup{
          color: gray;
          text-decoration: none;
        }


        // @include mobile {
        //   display: block;
        // }
      }
    }
  }
  @include mobile {
    height: 100vh;

    .card{
      height: 100%;
     .left{
     display: none;
     flex:0;
     }
     .right{
      padding: 20px;
     }
    }
   }
}
