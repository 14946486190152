@import "../../adminStyle.scss";

.adminPostManager{
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: calc(100vh -    100px);
    background-color: themed("bgSoft");
    color: themed("textColor");

    @include mobile {
      padding: 10px;
      min-height: calc(100vh -    100px);
    }

    @include tablet{
      padding: 20px;
    }
    
    .post-list {

      .heading-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;

        .image-heading{
          width: 10%;
          h3{
            text-align: center;
          }
        }

        .info-heading{
          width: 80%;
          display: flex;
          justify-content: space-between;

          .info-heading-common {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            h3{
              text-align: center;
            }
          }
        }

        .button-heading{
          width: 10%;
          h3{
            text-align: center;
          }
        }

      }



      display: grid;
      margin: 20px;
      .post-card {
        display: flex;
        align-items: center;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-around;
        overflow: hidden;

        .image-container {
          width: 10%;
          img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 50%;
          }
  
        }
     
        .post-info {
          width: 80%;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .info-container-common {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            p{
              width: "100%";
              overflow: hidden;
              word-wrap: break-word;
            }
          }

          img{
            width: 100px;
            height: 100px;
            object-fit: cover;
           
          }
        }

        .button-container {
          width: 10%;
          display: flex;
          justify-content: center;

          .boost-btn{
            margin-top: 10px;
            padding: 5px 10px;
            background-color: #41a300;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            justify-self: end;
            &:hover {
              background-color: #156301;
              
            }
          }
          // button {
          //   margin-top: 10px;
          //   padding: 5px 10px;
          //   // background-color: #f44336;
          //   color: white;
          //   border: none;
          //   border-radius: 5px;
          //   cursor: pointer;
          //   justify-self: end;
          //   &:hover {
          //     // background-color: #da190b;
              
          //   }
          // }
        }

   
      }
    }
  }
}