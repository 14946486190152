@import "../../adminStyle.scss";

.adminUserManager {
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: calc(100vh - 100px);
    background-color: themed("bgSoft");
    color: themed("textColor");

    @include mobile {
      padding: 10px;
      min-height: calc(100vh - 100px);
    }

    @include tablet {
      padding: 20px;
    }

    .top-bar-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 20px;
      flex-direction: row-reverse;

      .menu-left {
        display: flex;
        align-items: center;

        .search {
          display: flex;
          align-items: center;
          gap: 10px;
          border: 1px solid themed("border");
          border-radius: 5px;
          padding: 5px;
          position: relative;

          input {
            border: none;
            width: 300px;
            background-color: transparent;
            color: themed("textColor");
            outline: none;

            @include mobile {
              display: none;
            }

            @include tablet {
              width: 200px;
            }
          }
        }
        .dist-select{
          background-color: themed('bgSoft');
        }
      }

      .submit-btn {
        font-size: 1rem;
        background-color: #f44336;
        color: white;
        padding: 6px;
        border-radius: 10px;
        margin-left: 5px;
        :hover {
          background-color: #da190b;
        }
      }
    }

    .group-list {
      .group-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        font-size: 14px;
        text-align: left;
        border: 1px solid #ddd;
        color: themed("textColor");

        th, td {
          padding: 12px;
          border: 1px solid #ddd;
          text-align: center;
        }

        th {
          background-color: themed("bgSoft");
        }

        td {
          vertical-align: middle;
        }

        // tr:hover {
        //   background-color: #f5f5f5;
        //   color: black;
        // }

        .group-profile {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          display: inline-block;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .no-data {
          text-align: center;
          font-size: 18px;
          padding: 20px;
          color: #999;
        }       

        .button-container {
          width: 20%;
          display: flex;
          justify-content: center;

          .delete-btn {
            margin-top: 10px;
            padding: 5px 10px;
            background-color: #f44336;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
              background-color: #da190b;
            }
          }          
        }
      }
    }
  }
}
