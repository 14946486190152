@import "../../style.scss";

.grpSingle{
    @include themify($themes) {
        overflow:"scroll";
        background-color: themed("bgSoft");
        position: relative;
        z-index: 99;
        height: calc(100vh - 110px);
        overflow:auto;
        &::-webkit-scrollbar {
            display: none;
        }
        .goBack{
            width: 95%;
            height: 40px;
            background: transparent;
            position: absolute;
            // opacity: 50%;
            border-radius:50% ;
            display: flex;
            justify-content: space-between;
            margin: 10px;
            z-index: 100;
            button{
                text-decoration: none;
                background-color:transparent;
                color: black;
                border: none;
            }
            div{
              background-color: white;
              opacity: 0.7;
              padding: 10px;
              border-radius: 50%;
              cursor: pointer;
              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              span{
                font-weight: 700;
              }
            }
        }
        img{
            width: 100%;
            height: 50vh;
            object-fit: cover;
        }
        .detailsGrp{
            -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: -6px;
            height: 12rem;
            width: 98%;
            margin: auto;
            border-radius: 5px;
            background-color:themed("bg");
            padding-left: 10px;
            padding-right: 10px;
            color: themed("text-color");
            @include mobile{
                width: 90%;
                margin: auto;
            }
            
            .head{
                img{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    padding: 5px;
                }
            }
            .invite{
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                button{
                    width: 5rem;
                    background-color: themed("primary-color");
                    border-radius: 5px;
                    border: none;
                    font-weight: 700;
                    color: themed("text-color");
                }
            }
            .Tabs{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .profileTab{
                    // height: 100px;
                    border-radius: 20px;
                    // color: themed("textColor");
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 25%;
                    margin-bottom: 20px;
            
                    .tabs{
                      display: flex;
                      justify-content: space-evenly;
                      width: 100%;
                      height: 100%;
            
                      .line{
                        height: 100%;
                        width: 0%;
                        border: 1px solid rgb(172, 171, 171);
                      }
            
                      button{
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        // color: themed("text-color-blur");
                        color: gray;
                        cursor: pointer;
                        font-size: 16px;
                        margin: 0 10px;
                        padding: 5px;
                        text-decoration: none; 
                        font-weight: 700; 
                        // border-right: 1px solid gray;
                        
                        
            
                        &.active {
                          color: themed("textColor");
                          border-bottom: 2px solid themed("primary-color");
                          font-size: large;
                        }
            
                      }
                    }
                  }
                  button{
                    // width: rem;
                    background-color: themed("primary-color");
                    border-radius: 5px;
                    border: none;
                    font-weight: 700;
                    padding: 5px;
                }
                .more{
                    display: flex;
                    gap: 5px;
                }
            }
            .grpBody{
                display: flex;
                gap: 10px;
                background-color: themed("bgSoft");
                height: 50vh;
                position: relative;
                // overflow-y: auto;
                .leftBody{
                    width: 80%;
                    @include mobile{
                        width: 100%;
                    }
                }
                .rightBody{
                    width: 20%;
                    height: auto;
                    border-radius: 20px;
                    background-color: themed("bg");
                    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
                    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
                    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
                    border-radius: 20px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    position: sticky;
                    top: 0px;
                    a,p{
                        font-weight: 600;
                        font-size: 1rem;
                        margin: 10px;
                        color: themed("textColor");
                        cursor: pointer;
                    }    
                    @include mobile{
                        display: none;
                    }
                }
            }
        }
    }
}



.shareGrp {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    margin-bottom: 20px;
    width: 100%;

    .container {
      padding: 20px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          flex: 5;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            margin: 1rem;
          }

          textarea {
            border: none;
            outline: none;
            padding: 20px 10px;
            background-color: transparent;
            width: 100%;
            color: themed("textColor");
            resize: none;
            overflow: hidden;
            font-weight: 600;
          }
        
        }
        .right {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          .file {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 0px;
          }
        }
      }

      .inputError{
        color: rgb(241, 86, 86);
        font-size: small;
      }

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          gap: 20px;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            img {
              height: 20px;
            }

            span {
              font-weight: 600;
              font-size: 12px;
              color: gray;
            }
          }
        }
        .rightGrp {
          
          button {
          @include button-primary;
          color:themed("text-color");
          display: flex;
          justify-content: center;
          gap: 10px;
          font-weight: 700;
          padding: 10px;
          background-color : themed("primary-color");
          // background-color : themed("button-primary-bg");

            .loader{
              display: flex;
              justify-content: center;
              align-items: center;
              p{
                color: themed("bg");
                font-weight: 700;
                font-size: medium;
                margin-right: 5px;
              }
            }  

            .spinner{
              width: 1rem;
              height: 1rem;
              border: 2px solid themed("bg");
              border-radius: 50%;
              border-top: none;
              border-right: none;
              animation: spin 0.4s linear infinite ;
            }
          }

          button:hover {
            background-color: themed("primary-color-hover");
          }
        }
      }
    }
  }
}


