.language-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;

  .switch-container {
    position: relative;
    width: 60px;  // Smaller size for the toggle
    height: 30px;
    border-radius: 30px;
    background-color: #ddd;
    transition: background-color 0.4s ease;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 3px;

    .switch {
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      width: 25px;  // Slightly smaller switch
      height: 25px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transition: transform 0.4s ease, background-color 0.3s ease;
      padding: 1px;
      box-sizing: border-box;

      .text {
        font-size: 10px;
        text-align: center;
        line-height: 28px;
        color: #333;
        transition: color 0.3s ease;
      }
    }

    &.active {
      background-color: #4caf50;

      .switch {
        transform: translate(30px, -50%);
        background-color: #fff;

        .text {
          color: #333;
        }
      }
    }
  }
}





// .language-switch {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 10px;
//   padding: 10px;

//   .switch-container {
//     position: relative;
//     width: 60px;
//     height: 30px;
//     background-color: #ddd;
//     border-radius: 30px;
//     transition: background-color 0.4s ease;
//     cursor: pointer;
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

//     .switch {
//       position: absolute;
//       top: 3px;
//       left: 3px;
//       width: 24px;
//       height: 24px;
//       background-color: #fff;
//       border-radius: 50%;
//       transition: transform 0.4s ease, background-color 0.4s ease;
//       box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
//     }

//     &.active {
//       background-color: #4caf50;

//       .switch {
//         transform: translateX(30px);
//         background-color: #fff;
//       }
//     }
//   }

//   .label {
//     font-size: 16px;
//     font-weight: 500;
//     color: #333;
//     transition: color 0.4s ease;
//   }

//   .label.marathi {
//     color: #4caf50;
//   }

//   .label.english {
//     color: #007bff;
//   }
// }
