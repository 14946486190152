@import "../../style.scss";

.savedPage{
    @include themify($themes) {
        // width: 100%;
        height: calc(100vh - 110px);
        overflow:auto;
        padding: 10px;
        border: none;
        background-color: themed('bg');
        // background-color: red;
        color: themed('textColor');
        padding: 20px;
        &::-webkit-scrollbar {
            display: none;
        }
        .noPages{
            button{
                background-color: themed("primary-color");

                a{
                    color: themed('text-color');
                }
            }
        }

        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
        }
        .userPost{
            margin-top: 50px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
            place-items: center;
            @include mobile{
                grid-template-columns: 1fr 1fr;
                gap: 5px;
            }
            .post{
                box-shadow: 0px 0px 5px lightgray;
                border-radius: 10px;
                height:max-content;
                margin-bottom: 20px;
                width: 350px;
                min-height: 310px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                img{
                    object-fit: contain;
                }
                .postName{
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    img{
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                h2{
                    font-weight: 700;
                }
                .actions{
                    padding: 10px;
                    display: flex;
                    gap: 10px;
                    
                }
            }
        } 
    }
}