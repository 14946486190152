@import "../../adminStyle.scss";

.adminDashboard{
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    min-height: calc(100vh -    100px);
    background-color: themed("bgSoft");
    color: themed("textColor");

    .analyticsContainer{
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include tablet{
        flex-direction: column;
      }
    }

    @include mobile {
      padding: 10px;
      min-height: calc(100vh -    100px);
    }

    @include tablet{
      padding: 20px;
    }
  }
 }