@import "../../style.scss";

// .message-container{
//   @include themify($themes) {
//     padding: 20px 20px;
//     background-color: themed("bgSoft");
//     min-height: 400px;

//     @include mobile {
//       padding: 10px;
//       min-height: 100px;
//     }

//     @include tablet{
//       padding: 20px;
//     }
//   }
//  }

.message-container {

  @include themify($themes) {
    // padding: 20px 0px;
    background-color: themed("bg");
    min-height: 400px;
    color: themed("textColor");
    height: 100vh;

    // code for mobile view



    @include tablet {
      // padding: 20px;
    }



    // for web view

    display: flex;
    height: calc(100vh - 70px);

    .friends-list {
      width: 300px;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding: 5px;
      padding-top: 10px;
      max-height: 100vh;
      overflow-y: scroll;

      .backButton{
        display: flex;
        align-items: center;
        padding: 10px;
     
        span{
          margin-left: 10px;
        }
      }

      .friend-item {
        padding: 10px;
        cursor: pointer;
      }
    }

    .chat-window {
      background-color: themed('bgSoft');
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .chat-messages {
        flex-grow: 1;
        overflow-y: auto;
        // padding: 10px;

        .nameContainer {
          display: flex;
          align-items: center;
          // justify-content: center;
          border-bottom: 1px solid #ccc;
          padding: 20px;
          position: sticky;
          top: 0px;
          background-color: themed("bg");

          div {
            margin-left: 10px;
          }
        }

        .message {
          padding: 10px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;

          span {
            &.me {
              background-color: lightsteelblue;
              align-self: flex-end;
              padding: 12px;
              border-radius: 20px;
            }

            &.friend {
              background-color: #93a3fd;
              align-self: flex-start;
              padding: 12px;
              border-radius: 20px;
            }
          }
        }
      }

      .input-container {
        display: flex;
        padding: 10px;
        border-top: 1px solid #ccc;
        background-color: themed("bg");
        position: sticky;

        input {
          flex-grow: 1;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        button {
          margin-left: 10px;
          padding: 5px 10px;
          background-color: #4CAF50;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .select-friend {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 18px;
      }
    }

    @include mobile {
      // padding: 10px;
      min-height: 100px;
      // height: calc(100vh - 120px);
      height: 100vh;

      .chat-window {

        .chat-messages {

          .nameContainer {
            padding: 10px 10px;
            position: fixed;
            top: 0px;
            width: 100%;
            background-color: themed("bg");
          }
        }

        .input-container {
          position: sticky;
          bottom: 0px;
          background-color: themed("bg");
        }

        .select-friend {
          display: none !important;
          flex: 0;
        }
        
      }

      .friends-list {
        width: 100%;
        border: none;
        width: 100% !important;
        border-right: none !important;
        border-left: none !important;

        &.active {
          display: none; // remvoe it
        }

      }
    }
  }
}