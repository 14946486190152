@import "../../style.scss";

.singleEventPage {
    @include themify($themes) {
        background-color: themed('bg');
        color: themed('textColor');
        // padding: 20px;
        height: calc(100vh - 110px);
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .eveCover {
            width: 100%;
            height: 50vh;
            object-fit: cover;
        }
        .head-Container{
            display: flex;
            justify-content: space-between;

            .date {
                width: 150px;
                height: auto;
                border-radius: 20px;
                padding: 0;
                border-radius: 0 0 20px 20px;
                position: relative;
                top: -92px;
                left: 20px;
    
                .headDate {
                    height: 50px;
                    background-color: red;
                    border-radius: 20px 20px 0 0;
                }
    
                .btDate {
                    text-align: center;
                    padding: 30px;
                    font-size: 30px;
                    font-weight: 600;
                    color: themed("textColor");
                    box-shadow: 1px 1px 10px lightgray;
                    border-radius: 0 0 20px 20px;
                    background-color: themed('bg');
                }
            }
            .actionBtn {
                background-color: themed("primary-color");
                color: themed('text-color');                
                border-radius: 5px;
                height: 35px;
                margin-right: 20px;
                display: flex;
                padding-right: 5px;
                padding-left: 5px;

                .button-clr{
                    color: themed('text-color');
                }
            }
        }

        

        .eventDetails {
            margin-top: 50px;
            width: 50%;
            margin-right: auto;
            box-shadow: 1px 1px 10px lightgray;
            margin-left: auto;
            border-radius: 10px;
            background-color: themed("bg");
            padding: 20px;
            margin-bottom: 50px;

            .btn-container{
                display: flex;
                gap: 2px;
            }

            .catName {
                background-color: themed("primary-color");
                color: themed("text-color");
                padding: 5px;
                border-radius: 5px;

                .button-clr{
                    color: themed('text-color');
                }
            }

            .detDesc {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 20px;
                font-weight: 600;
                font-size: 14px;
            }

            .userLink {
                text-decoration: none;
                font-weight: 700;
                font-size: large;
                color: themed("textColor");
            }
        }
    }
}

.members{
    padding: 20px;
    .member{
        background-color: f9f8f8;
        padding: 20px;
        margin: 10px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right{
            display: flex;
            justify-items: center;
            align-items: center;
            gap: 20px;

            h3{
                color: black;
                cursor: pointer;               
            }

            .profile-img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }
        .removeParticipant{
            .actionBtn {
                background-color: rgb(180 180 245);
                color: black;                
                border-radius: 5px;
                height: 35px;
                margin-right: 20px;
                display: flex;
                padding-right: 5px;
                padding-left: 5px;
                display: flex;                    
                align-items: center;
                color: red;
            }
        }
    }

    // @include themify($themes) {
    //     padding: 50px;
    
    //     .member{
    //         background-color: themed("bg");
    //         padding: 20px;
    //         margin: 10px;
    //         border-radius: 5px;
    //         display: flex;
    //         justify-content: space-between;
    //         align-items: center;

    //         .right{
    //             display: flex;
    //             justify-items: center;
    //             align-items: center;
    //             gap: 20px;
    //             h3{
    //                 color: themed('textColor');
    //                 cursor: pointer;
    //                 &:hover{
                
    //                     color: themed("primary-color-hover");
    //                 }
    //             }
    //             .profile-img{
    //                 width: 40px;
    //                 height: 40px;
    //                 border-radius: 50%;
    //             }
    //             a{
    //                 color: themed('textColor');
    //             }
    //         }        
    //         .removeParticipant{
    //             .actionBtn {
    //                 background-color: themed("primary-color");
    //                 color: themed('text-color');                
    //                 border-radius: 5px;
    //                 height: 35px;
    //                 margin-right: 20px;
    //                 display: flex;
    //                 padding-right: 5px;
    //                 padding-left: 5px;
    //                 display: flex;                    
    //                 align-items: center;
    //                 color: red;
    //             }
    //         }
    //     }
    // }
}
