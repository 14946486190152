@import "../../../style.scss";

.shareAds {
  @include themify($themes) {
    background-color: themed("bg");
    color: themed("textColor");
    margin-top: 100px;
    width: 90%; 
    height: 100vh;
    margin: auto;
    padding: 50px;

    @include mobile{
      width: 100%;
      margin: 5px;
    }
    
    .container {
      -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
      -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
      box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
      border-radius: 20px;
      padding: 20px;
      margin-top: 50px;
      width: 50%;
      margin: auto;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          flex: 5;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            margin: 1rem;
          }

          input {
            border: none;
            outline: none;
            padding: 20px 10px;
            background-color: transparent;
            width: 100%;
            color: themed("textColor");
            resize: none;
            overflow: hidden;
            font-weight: 600;
          }
          textarea {
            border: none;
            outline: none;
            padding: 20px 10px;
            background-color: transparent;
            width: 100%;
            color: themed("textColor");
            resize: none;
            overflow: hidden;
            font-weight: 600;
          }
        
        }
        .right {
          flex: 1;
          display: flex;
          justify-content: flex-end;

          .loader{
            display: flex;
            justify-content: center;
            align-items: center;
            p{
              color: themed("bg");
              font-weight: 700;
              font-size: medium;
              margin-right: 5px;
            }
          }  

          .spinner{
            width: 1rem;
            height: 1rem;
            border: 2px solid themed("bg");
            border-radius: 50%;
            border-top: none;
            border-right: none;
            animation: spin 0.4s linear infinite ;
          }
          
          .file {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 0px;
          }
        }
      }

      .inputError{
        color: rgb(241, 86, 86);
        font-size: small;
      }

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          gap: 20px;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            img {
              height: 20px;
            }

            span {
              font-weight: 600;
              font-size: 12px;
              color: gray;
            }
          }
        }

        .center{
            select{
                border-radius: 5px;
                padding: 5px 10px;
                border:1px solid gray;
            }
        }
        .right {
          
          button {
          @include button-primary;
          color: black;
          display: flex;
          justify-content: center;
          gap: 10px;
          font-weight: 700;
          padding: 10px;
          background-color : themed("primary-color");
          // background-color : themed("button-primary-bg");
          }

          button:hover {
            background-color: themed("primary-color-hover");
          }
        }
      }
    }
  }
}
