@import "../../adminStyle.scss";

.boostPost{
    background-color: white;
    
    h1 {
        margin-top: 50px;
      }

    .modalGroups {
        margin: 10px;
        width: 80%;
        // width: 75vw;
        padding: 20px;
        border-radius: 10px;
        background-color: white;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
  
        
  
        .date-input {
          border: 1px solid gray;
          border-radius: 5px;
          outline: none;
          padding: 5px;
          font-size: 1rem;
          font-weight: 600;
          width: 100%;
          background-color: white;
          color: black;
         
        }
  
        .selInp {
          border: 1px solid gray;
          border-radius: 5px;
          outline: none;
          padding: 5px;
          font-size: 1rem;
          font-weight: 600;
          width: 100%;
          background-color: white;
          color: black;
          
        }
  
        .inpFile{
          width: 50%;
          padding: 30px;
         
        }
  
        .date-container{
          width: 90%;
          height: 60px;
          display: flex;
          justify-content: space-between;
          gap: 100px;
          align-items: center;
          
          .innerDate-container{
              width: 100%;
              .labelForDate{
                  font-size: 14px;
                  font-weight: 600;
              }
          }
        }
  
        button {
          margin-top: 20px;
          padding: 10px 20px;
          background-color: green;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: 700;
        }

        .custom-option {
            display: flex;
            align-items: center;
          }
          
          .custom-checkbox {
            margin-right: 10px;
          }
    }
}