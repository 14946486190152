.selectFriend{

    .list{
        width: 80%;
        height: 50%;
        margin: auto;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}