.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    margin-top: 20px;
  
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .terms-section {
      margin-bottom: 20px;
  
      h3 {
        font-size: 18px;
        color: #444;
      }
  
      p {
        font-size: 16px;
        line-height: 1.6;
      }
  
      a {
        color: #007bff;
        text-decoration: none;
      }
  
      a:hover {
        text-decoration: underline;
      }
    }
  }
  